<template>
  <b-row>
    <b-col cols="12">
      <b-nav pills fill>
        <b-nav-item
          v-for="(item, index) in marketplaces"
          :key="index"
          :active="
            $route.name === item.routeList || $route.name === item.routeForm
          "
          :to="{ name: item.routeList }"
          :disabled="item.disabled"
          :title="
            item.disabled ? 'Belum Tersedia' : 'Ke Halaman ' + item.marketplace
          "
          :style="{ cursor: item.disabled ? 'not-allowed' : 'pointer' }"
        >
          <b-img
            :src="
              require(`@/assets/images/marketplace/${item.marketplace.toLowerCase()}.png`)
            "
            class="img-fluid icon-marketplace"
            style="width: 25px; height: 25px; margin-right: 5px"
            :style="{ filter: item.disabled ? 'grayscale(100%)' : '' }"
          />

          {{ item.marketplace }}
        </b-nav-item>
      </b-nav>
    </b-col>
    <b-col cols="12">
      <transition :name="routerTransition" mode="out-in">
        <router-view />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BNav, BNavItem, BImg } from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';

export default defineComponent({
  name: 'MarketplaceProductList',
  components: {
    BRow,
    BCol,
    BCard,
    BNav,
    BNavItem,
    BImg,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
  data() {
    return {
      marketplaces: [
        {
          routeList: 'ProductsTokopedia',
          routeForm: 'FormProductTokopedia',
          marketplace: 'Tokopedia',
        },
        {
          routeList: 'ProductsLazada',
          routeForm: 'FormProductLazada',
          marketplace: 'Lazada',
        },
        {
          routeList: 'ProductsShopee',
          routeForm: 'FormProductShopee',
          marketplace: 'Shopee',
        },
        {
          routeList: 'ProductsTiktok',
          routeForm: 'FormProductTiktok',
          marketplace: 'Tiktok',
          disabled: true,
        },
        {
          routeList: '',
          marketplace: 'Blibli',
          disabled: true,
        },
      ],
    };
  },
});
</script>
